<template>
  <div v-if="orderedRecords">    
    <div
      v-for="(group, index) in Object.keys(orderedRecords)"
      :key="index"
    >
      <section v-if="group.length">
        <h2 class="record-title">{{ group }}</h2>
        <div
          v-for="(record, i) in Object.values(orderedRecords[group])"
          :key="i"
        > 
        <div v-show="noFilter || modules.find(el => {return el.id === record[0].type || record[0].type.includes('exame-personalizado')})" >
            <ModulesHandler 
              :record="record[0]"
            />
          </div>

        </div>
      </section>
    </div>    
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { getDocTypes } from '@/utils/documentHelper.js'
import { getHistoryModulesByGroup } from '@/utils/modulesHelper.js'

export default {
  props: {
    records: Array,
    noFilter: Boolean,
  },
  components: {
    ModulesHandler: () => import('@/components/MedicalRecords/HistoryModulesHandler')
  },
  computed: {
    ...mapState({
      modules: state => state.attendance.history.modules,
      groups: state => state.attendance.menu.groups,
    }),
  },
  data() {
    return {
        docs: [],
        order: getHistoryModulesByGroup(),
        typeDoc: getDocTypes(),
        orderedRecords: [],
        structureTitles: [],
    }
  },
  methods: {
    getStructure(){
      const structureTitles = [];

      Object.keys(this.order).map((group) => {
        this.order[group].map((title) => {
          structureTitles[title] = group;
        })
      })
      
      this.structureTitles = structureTitles;
    },
    orderRecords(){      
      const historyByGroup = [];

      this.records.find((record) => {        
        const group = (!record.type.includes('exame-personalizado') ? this.structureTitles[record.type] : 'Exames complementares');   
        
        if(!historyByGroup[group]){
          historyByGroup[group] = [];
        }

        if(!historyByGroup[group][record.type]){
          historyByGroup[group][record.type] = [];
        }

        historyByGroup[group][record.type].push(record);
      });

      this.orderedRecords = historyByGroup;
      
      const orderedHistoryByGroup = {};
      Object.keys(this.structureTitles).forEach((type) => {
        const group = this.structureTitles[type];
        if (historyByGroup[group]) {
          if (!orderedHistoryByGroup[group]) {
            orderedHistoryByGroup[group] = {};
          }
          if (historyByGroup[group][type]) {
            orderedHistoryByGroup[group][type] = historyByGroup[group][type];
          }
        }
      });
      if (historyByGroup['Exames complementares']) {
        orderedHistoryByGroup['Exames complementares'] = historyByGroup['Exames complementares'];
      }
      this.orderedRecords = orderedHistoryByGroup;
    },
    getDocuments (){
      this.api
      .getAttendanceFiles(this.records[0].attendance_id)
      .then(res => {
        this.docs = res.data;
      })
      .catch(err => {
        console.log('Err', err)
      });
    },
    async openFile(attendanceFile) {
      const res = await this.api.getDocTemporaryUrl(attendanceFile.path)
      window.open(res.data, '_blank');
    }
  },

  mounted() {
    this.getStructure()
    this.orderRecords()
  },

  watch: {
    records: function(value) {
      this.orderRecords()
      if(value && value[0])
        this.getDocuments()
    },
    modules: function() {
      this.orderRecords()
    }
  }
}
</script>

<style lang="scss" scoped>
  .record-title {
    color: var(--dark-blue);
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px !important;
    margin-top: 25px;
  }

</style>
